import './assets/css/App.css';
import AcxHeader from './components/AcxHeader';
import BcxSectionA from './components/BcxSectionA';
import BcxSectionB from './components/BcxSectionB';
import BcxSectionC from './components/BcxSectionC';
import BcxSectionD from './components/BcxSectionD';
import BcxSectionE from './components/BcxSectionE';
import BcxSectionF from './components/BcxSectionF';
import BcxSectionForm from './components/BcxSectionForm';
import BcxSectionG from './components/BcxSectionG';
import CcxFooter from './components/CcxFooter';
import ReducaoJuro from './components/popups/ReducaoJuro';

function App() {
  return (
    <div className="App">
      {/* <ReducaoJuro /> */}
      <AcxHeader />
      <BcxSectionA />
      <BcxSectionB />
      <BcxSectionC />
      <BcxSectionD />
      <BcxSectionE />
      <BcxSectionF />
      <BcxSectionForm />
      <BcxSectionG />
      <CcxFooter />
    </div>
  );
}

export default App;
