import React from 'react'
import Imagem from '../assets/imgs/documentacao.png'

const BcxSectionF = () => {
  return (
    <section id="sectionF">
      <div className="container">
        <div className="texto">
          <h2>
            Documentação
            <br />
            <strong>necessária</strong>
          </h2>

          <p>
            • RG, CPF e comprovante de endereço e renda dos sócios. Pessoas
            casadas ou com união estável devem apresentar também documentação do
            cônjuge. Cópias devem ser coloridas.
          </p>
          <p>• Negativas de tributos municipais.</p>
          <h3>Mais documentos abaixo, conforme tipo de empresa:</h3>
          <p className="tipoEmpresa">
            Se empresa enquadrada como MEI:
            <li>
              - CCMEI: Certificado de Condição de Microempreendedor Individual
            </li>
            <li>- DASN SIMEI: Declaração Anual do SIMEI</li>
          </p>
          <p className="tipoEmpresa">
            • Se empresa enquadrada como ME / EPP:
            <li>
              - Declaração de Firma Individual, Contrato Social <br />
              Empresas do Simples Nacional: PGDAS-D - Programa Gerador do
              Documento de Arrecadação do Simples Nacional
            </li>
            <li>
              - Declaratório, com faturamento dos últimos 12 meses Empresa
              do Lucro Presumido: SPED Contábil | Recibo Balanço e DRE
            </li>
          </p>
          <p>
            • A documentação completa será solicitada somente após análise das
            informações fornecidas no formulário. Preencha com seus dados e
            aguarde o contato.
          </p>
          <span>
            <strong>
              Se você não é associado(a) da Sicredi Pioneira ou Cresol, será
              necessária a abertura de uma conta corrente.
            </strong>
          </span>
        </div>
        <div className="imagem">
          <img src={Imagem} alt="Mulher de óculos com olhar pensativo" />
        </div>
      </div>
    </section>
  )
}

export default BcxSectionF
