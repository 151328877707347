import React from 'react'
import logoCredCaxias from '../assets/imgs/logo.png'

const AcxHeader = () => {
    return (

        <section id="header">
            <div className="container">
                <img src={logoCredCaxias} alt="Logotipo da Cred Caxias" />

                <h1>Novos caminhos <br /><strong>para empresas<br />mais fortes</strong></h1>
            </div>
        </section>

    )
}

export default AcxHeader