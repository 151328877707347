import React from 'react'
import Sicredi from '../assets/imgs/logo-1.jpg'
import Sebrae from '../assets/imgs/logo-2.jpg'
import RsGaranti from '../assets/imgs/logo-3.jpg'
import Prefeitura from '../assets/imgs/logo-4.jpg'
import Cresol from '../assets/imgs/logo-5.jpg'
import BancoFamilia from '../assets/imgs/logo-6.png'

const CcxFooter = () => {
  return (
    <section id="footer">
      <div className="container">
        <div className="logos">
          <a href="https://sicredipioneira.com.br" target={'_blank'}>
            {' '}
            <img src={Sicredi} alt="Logo da Sicredi" />
          </a>
          <a href="https://sebraers.com.br" target={'_blank'}>
            {' '}
            <img src={Sebrae} alt="Logo do Sebrae" />
          </a>
          <a href="https://cresol.com.br" target={'_blank'}>
            <img src={Cresol} alt="Logo da Cresol" />
          </a>
          <a href="https://www.rsgaranti.org.br/" target={'_blank'}>
            {' '}
            <img src={RsGaranti} alt="Logo da RS Garanti" />
          </a>
          <a href="https://bf.org.br/" target={'_blank'}>
            {' '}
            <img src={BancoFamilia} alt="Logo do Banco da família" />
          </a>
          <a href="https://caxias.rs.gov.br/" target={'_blank'}>
            {' '}
            <img src={Prefeitura} alt="Logo da Prefeitura de Caxias do Sul" />
          </a>
        </div>
      </div>
    </section>
  )
}

export default CcxFooter
